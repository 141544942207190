import { useState } from 'react'
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'

import { getMealsMacros } from '../../../../../helpers/meals'

import { DietMealFormValues, IDietMeal } from '../../../../../types/IDietMeal'
import { DietMealCard } from '../DietMealCard'
import { ButtonContainer } from '../../../../Shared/Buttons/ButtonContainer'
import { MEAL_ORDER } from '../../../../../constants/meals'
import { getDayNameFromDate } from '../../../../../helpers/dates'

interface DietRowProps {
  day: number
  startDate?: Date | null
  isLastDay?: boolean
  isDisabled?: boolean
  meals: IDietMeal[]
  onAddMeal: (v: DietMealFormValues) => void
  onRemove: () => void
  onSelect: (m: IDietMeal) => void
  onEditMeal: (m: IDietMeal) => void
  onRemoveMeal: (id: string) => void
  onDayActivation: (day: number, active: boolean) => void
}

const DietRow = ({
  day,
  startDate,
  isDisabled,
  isLastDay,
  meals,
  onAddMeal,
  onRemove,
  onSelect,
  onEditMeal,
  onRemoveMeal,
  onDayActivation,
}: DietRowProps) => {
  const [open, setOpen] = useState(false)
  const { energy, proteins, carbs, fats } = getMealsMacros(meals)

  function toggleDay(e: any) {
    e.stopPropagation()
    onDayActivation(day, Boolean(isDisabled))
  }

  const nextMealOrder = Math.min(meals.length + 1, Object.keys(MEAL_ORDER).length)

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 0, color: isDisabled ? 'lightgray' : 'default' } }}
        hover
        onClick={() => setOpen(!open)}
      >
        <TableCell width={70}>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th'>
          <Typography sx={{ width: 80 }} variant='h5'>
            Dzień {day}
          </Typography>
          {startDate && (
            <Typography variant='caption'>
              {getDayNameFromDate(new Date(startDate), day - 1)}
            </Typography>
          )}
        </TableCell>
        <TableCell align='right'>
          <strong>{energy}</strong>
        </TableCell>
        <TableCell align='right' sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
          {proteins}
        </TableCell>
        <TableCell align='right' sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
          {carbs}
        </TableCell>
        <TableCell align='right' sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
          {fats}
        </TableCell>
        <TableCell align='right'>
          <IconButton aria-label='expand row' size='small' onClick={toggleDay}>
            {isDisabled ? <CheckBoxOutlineBlankIcon /> : <CheckBoxOutlinedIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box mx={1} my={4}>
              <Grid container mb={2} spacing={4}>
                {meals.map((meal) => (
                  <Grid key={meal.id} item md={6}>
                    <DietMealCard
                      meal={meal}
                      onSelect={onSelect}
                      onEdit={onEditMeal}
                      onRemove={onRemoveMeal}
                    />
                  </Grid>
                ))}
              </Grid>

              <ButtonContainer alignButtons='right'>
                {isLastDay && (
                  <Button color='error' onClick={onRemove} style={{ marginRight: 16 }}>
                    Usuń
                  </Button>
                )}
                <Button
                  onClick={() =>
                    onAddMeal({ days: [day], order: nextMealOrder, diet_meal_products: [] })
                  }
                  variant='outlined'
                >
                  Dodaj posiłek
                </Button>
              </ButtonContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export { DietRow }
