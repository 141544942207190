import { useQuery } from '@tanstack/react-query'

import { getMeal } from '../../../../../api/meals'
import { MEALS } from '../../../../../constants/query_keys'
import { MealFormValues } from '../../../../../types/IMeal'
import { GenericDrawer } from '../../../../Shared/Lists/GenericDrawer'
import { Spinner } from '../../../../Shared/Loaders'
import { MealEditorForm } from './MealEditorForm'

type MealEditorDrawerProps = {
  open: boolean
  mealId?: string | null
  onUpdate: (v: MealFormValues) => Promise<void>
  onCreate: (v: MealFormValues) => Promise<void>
  onClose: () => void
}

const MealEditorDrawer = ({ open, mealId, onUpdate, onCreate, onClose }: MealEditorDrawerProps) => {
  const { isLoading, data: meal } = useQuery([MEALS, mealId || ''], () => getMeal(mealId || ''), {
    enabled: Boolean(mealId),
  })

  async function submit(values: MealFormValues) {
    try {
      mealId ? await onUpdate(values) : await onCreate(values)
      onClose()
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  const isFetching = open && mealId && isLoading

  return (
    <GenericDrawer open={open} onClose={onClose} title={meal ? 'Edytuj posiłek' : 'Utwórz posiłek'}>
      {isFetching ? <Spinner /> : <MealEditorForm meal={meal} onSubmit={submit} />}
    </GenericDrawer>
  )
}

export { MealEditorDrawer }
