import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

interface RowActionProps<T> {
  row: { id: string } & T
  onEdit: (row: T) => void
  onRemove: (id: string) => void
  onCopy?: (id: string) => void
}

const RowActions = <T extends unknown>({ row, onEdit, onRemove, onCopy }: RowActionProps<T>) => {
  return (
    <>
      {onCopy && (
        <IconButton onClick={() => onCopy(row.id)} color='success'>
          <ContentCopyIcon />
        </IconButton>
      )}
      <IconButton onClick={() => onEdit(row)} color='info'>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => onRemove(row.id)} color='error'>
        <DeleteIcon />
      </IconButton>
    </>
  )
}

export { RowActions }
