import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from '../../../../store'
import { closeConfirm } from '../../../../store/slices/ui'

const ConfirmDialog = () => {
  const dispatch = useDispatch()
  const { isConfirmVisible, title, text, onConfirm, onCancel } = useSelector(
    (state: RootState) => state.ui,
  )

  function close() {
    onCancel && onCancel()
    dispatch(closeConfirm())
  }

  function confirm() {
    onConfirm && onConfirm()
    dispatch(closeConfirm())
  }

  return (
    <Dialog open={isConfirmVisible} onClose={close}>
      <DialogTitle>{title}</DialogTitle>
      {text && (
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={close} autoFocus>
          Anuluj
        </Button>
        <Button onClick={confirm}>Tak</Button>
      </DialogActions>
    </Dialog>
  )
}

export { ConfirmDialog }
