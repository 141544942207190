import { useState } from 'react'
import { Box, Button } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { addProduct, deleteProduct, getProducts, updateProduct } from '../../../api/products'
import { Content } from '../../Layout'
import { ProductEditorDrawer } from './Components/ProductEditor'
import { ProductFormValues } from '../../../types/IProduct'
import { ProductList } from './Components/ProductList'
import { PRODUCTS } from '../../../constants/query_keys'
import { useSorting, useToggler } from '../../../hooks'
import { ProductFinderDrawer } from './Components/ProductFinder'
import { usePagination } from '../../../hooks'
import { PAGE_SIZE_DEFAULT } from '../../../constants'
import { SearchField } from '../../Shared/Forms/SearchField'

const Products = () => {
  const queryClient = useQueryClient()
  const { page, setPage } = usePagination()
  const { field, order, setSort } = useSorting('name')
  const [searchText, setSearchText] = useState('')

  const { isLoading, error, data } = useQuery(
    [PRODUCTS, page, field, order, searchText],
    () => getProducts({ limit: PAGE_SIZE_DEFAULT, page, field, order, searchText }),
    {
      keepPreviousData: true,
    },
  )
  const mutationCreate = useMutation(addProduct)
  const mutationUpdate = useMutation(updateProduct)
  const mutationDelete = useMutation(deleteProduct)

  const {
    isOpen: isEditorOpen,
    open: openEditor,
    close: closeEditor,
    selectedItem: selectedProduct,
  } = useToggler<ProductFormValues>()

  const {
    isOpen: isFinderOpen,
    open: openFinder,
    close: closeFinder,
  } = useToggler<ProductFormValues>()

  function create(values: ProductFormValues) {
    return mutationCreate.mutate(values, {
      onSuccess: () => queryClient.invalidateQueries([PRODUCTS])
    })
  }

  function update(values: ProductFormValues) {
    mutationUpdate.mutate(values, {
      onSuccess: () => queryClient.invalidateQueries([PRODUCTS]),
    })
  }

  function remove(id: string) {
    mutationDelete.mutate(id, {
      onSuccess: () => queryClient.invalidateQueries([PRODUCTS]),
    })
  }

  return (
    <Content isLoading={isLoading} error={error as Error}>
      <>
        <Box mb={3} display='flex' justifyContent='flex-end'>
          <Button variant='outlined' onClick={() => openFinder()} style={{ marginRight: 16 }}>
            Dodaj z USDA
          </Button>
          <Button variant='contained' onClick={() => openEditor()}>
            Dodaj produkt
          </Button>
        </Box>

        <Box component='form' noValidate autoComplete='off' mb={2}>
          <SearchField onChange={setSearchText} />
        </Box>

        {data && (
          <ProductList
            products={data.rows}
            count={data.count}
            page={page}
            isLoading={isLoading}
            onEdit={openEditor}
            onRemove={remove}
            onPageChange={setPage}
            onSortChange={(sortItem) => setSort(sortItem[0].field, sortItem[0].sort)}
          />
        )}

        <ProductEditorDrawer
          open={isEditorOpen}
          product={selectedProduct}
          onCreate={create}
          onUpdate={update}
          onClose={closeEditor}
        />

        <ProductFinderDrawer open={isFinderOpen} onSubmit={create} onClose={closeFinder} />
      </>
    </Content>
  )
}

export { Products }
