import { AppBar, Toolbar, Typography, useMediaQuery, useTheme, IconButton } from '@mui/material'
import { useLocation } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'

import { pages } from './Sidebar'

import './Topbar.scss'

interface TopbarProps {
  onMenuToggle: () => void
}

const Topbar = ({ onMenuToggle }: TopbarProps) => {
  const { pathname } = useLocation()
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))

  const title = pages.find((page) => pathname === page.path)?.title

  return (
    <AppBar className='Topbar'>
      <Toolbar>
        {!isLargeScreen && (
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={onMenuToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography variant='h6' noWrap component='div'>
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export { Topbar }
