import { useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { addMeal, copyMeal, deleteMeal, getMeals, updateMeal } from '../../../api/meals'
import { MEALS } from '../../../constants/query_keys'
import { Content } from '../../Layout'
import { MealEditorDrawer } from './Components/MealEditorDrawer'
import { MealViewerDrawer } from './Components/MealViewerDrawer'
import { MealList } from './Components/MealList'
import { IMeal, MealFormValues } from '../../../types/IMeal'
import { CopyDialog } from '../../Shared/Dialogs/CopyDialog'
import { usePagination, useSorting } from '../../../hooks'
import { PAGE_SIZE_DEFAULT } from '../../../constants'
import { SearchField } from '../../Shared/Forms/SearchField'
import { SuggestedOrderField } from '../../Shared/Forms/SuggestedOrderField'
import { SelectProductsField } from '../../Shared/Forms/SelectProductsField'

const Meals = () => {
  const queryClient = useQueryClient()
  const { page, setPage } = usePagination()
  const { field, order, setSort } = useSorting('title')
  const [searchText, setSearchText] = useState('')
  const [mealOrder, setMealOrder] = useState<number[] | null>(null)
  const [products, setProducts] = useState<string[] | null>(null)

  const { isLoading, error, data } = useQuery(
    [MEALS, page, field, order, searchText, mealOrder, products],
    () =>
      getMeals({
        limit: PAGE_SIZE_DEFAULT,
        page,
        field,
        order,
        include: ['products'],
        searchText,
        mealOrder,
        products
      }),
    {
      keepPreviousData: true,
    },
  )
  const mutationCreate = useMutation(addMeal)
  const mutationUpdate = useMutation(updateMeal)
  const mutationDelete = useMutation(deleteMeal)

  const [editorOpen, setEditorOpen] = useState(false)
  const [viewerOpen, setViewerOpen] = useState(false)
  const [selectedCopy, setSelectedCopy] = useState<{ title: string; id: string } | null>(null)
  const [selectedMeal, setSelectedMeal] = useState<IMeal | null>(null)

  function openEditor(meal?: IMeal) {
    setViewerOpen(false)
    setSelectedMeal(meal || null)
    setEditorOpen(true)
  }
  function closeEditor() {
    setSelectedMeal(null)
    setEditorOpen(false)
  }
  function openViewer(meal: IMeal) {
    setSelectedMeal(meal)
    setViewerOpen(true)
  }
  function closeViewer() {
    setSelectedMeal(null)
    setViewerOpen(false)
  }

  async function create(values: MealFormValues) {
    try {
      await mutationCreate.mutateAsync(values)
      queryClient.invalidateQueries([MEALS])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  async function update(values: MealFormValues) {
    try {
      await mutationUpdate.mutateAsync(values)
      queryClient.invalidateQueries([MEALS])
      toast.success('Posiłek został zaktualizowany.')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  async function remove(id: string) {
    try {
      await mutationDelete.mutateAsync(id)
      queryClient.invalidateQueries([MEALS])
      toast.info('Posiłek został usunięty.')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  async function copy({ name }: { name: string }) {
    if (!selectedCopy) {
      return
    }

    try {
      await copyMeal({ id: selectedCopy.id, newTitle: name })
      queryClient.invalidateQueries([MEALS])
      toast.success('Posiłek został skopiowany.')
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  return (
    <Content isLoading={isLoading} error={error as Error}>
      <>
        <Box mb={3} display='flex' justifyContent='flex-end'>
          <Button variant='contained' onClick={() => openEditor()}>
            Stwórz posiłek
          </Button>
        </Box>

        <Grid container component='form' noValidate autoComplete='off' mb={2} spacing={2}>
          <Grid item xs={12} md={4}>
            <SearchField onChange={setSearchText} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SuggestedOrderField onChange={setMealOrder} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectProductsField onChange={setProducts} />
          </Grid>
        </Grid>

        {data && (
          <MealList
            meals={data.rows}
            count={data.count}
            page={page}
            isLoading={isLoading}
            onEdit={openEditor}
            onRemove={remove}
            onCopy={setSelectedCopy}
            onSelect={openViewer}
            onPageChange={setPage}
            onSortChange={(sortItem) => setSort(sortItem[0].field, sortItem[0].sort)}
          />
        )}

        <MealEditorDrawer
          open={editorOpen}
          mealId={selectedMeal?.id}
          onCreate={create}
          onUpdate={update}
          onClose={closeEditor}
        />

        <MealViewerDrawer
          open={viewerOpen}
          mealId={selectedMeal?.id}
          onEdit={openEditor}
          onClose={closeViewer}
        />

        {selectedCopy && (
          <CopyDialog
            defaultName={selectedCopy.title}
            onCopy={copy}
            onClose={() => setSelectedCopy(null)}
          />
        )}
      </>
    </Content>
  )
}

export { Meals }
