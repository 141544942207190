export const SIDEBAR_WIDTH = 240

export const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? 'https://dietki-api.fbdev.usermd.net'
    : 'http://localhost:6060'

export const USDA_API_ENDPOINT = 'https://api.nal.usda.gov/fdc'
export const USDA_API_KEY = 'xThuzbOud5SG7CW5ytUlT2m37d4eI0QQXtUaz9Dq'

export const PAGE_SIZE_DEFAULT = 10
