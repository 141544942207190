import { ProductFormValues } from '../../../../../types/IProduct'
import { GenericDrawer } from '../../../../Shared/Lists/GenericDrawer'
import { ProductFinderForm } from './ProductFinderForm'

type ProductFinderDrawerProps = {
  open: boolean
  onSubmit: (v: ProductFormValues) => void
  onClose: () => void
}

const ProductFinderDrawer = ({ open, onSubmit, onClose }: ProductFinderDrawerProps) => {
  async function submit(values: ProductFormValues) {
    try {
      await onSubmit(values)
      onClose()
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  return (
    <GenericDrawer open={open} onClose={onClose} title='Szukaj w bazie USDA'>
      {open && <ProductFinderForm onSubmit={submit} />}
    </GenericDrawer>
  )
}

export { ProductFinderDrawer }
