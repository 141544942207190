import { ReactNode } from 'react'
import { Grid } from '@mui/material'

type ButtonContainerProps = {
  alignButtons: 'left' | 'right'
  children: ReactNode
}

const ButtonContainer = ({ alignButtons, children }: ButtonContainerProps) => {
  const jc = alignButtons === 'left' ? 'flex-start' : 'flex-end'

  return (
    <Grid container justifyContent={jc}>
      {children}
    </Grid>
  )
}

export { ButtonContainer }
