import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'

import { getCategories } from '../../../../../api/categories'
import { CATEGORIES } from '../../../../../constants/query_keys'
import { ICategory } from '../../../../../types/ICategory'
import { ProductFormValues } from '../../../../../types/IProduct'
import { ButtonContainer } from '../../../../Shared/Buttons/ButtonContainer'

type ProductEditorFormProps = {
  product?: ProductFormValues | null
  onSubmit: (v: ProductFormValues) => void
}

const ProductEditorForm = ({ product, onSubmit }: ProductEditorFormProps) => {
  const { isLoading, data: categories } = useQuery([CATEGORIES], getCategories)

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ProductFormValues>({
    defaultValues: {
      id: product?.id,
      name: product?.name || '',
      energy: product?.energy || 0,
      protein: product?.protein || 0,
      fat: product?.fat || 0,
      carb: product?.carb || 0,
      units: product?.units || 'g',
      category: product?.category || null,
      category_id: product?.category_id || '',
    },
  })

  function changeProductCategory(
    category: ICategory | null,
    fieldOnChange: (...event: any[]) => void,
  ) {
    setValue('category_id', category?.id || '')
    fieldOnChange(category)
  }

  return (
    <>
      <Box mb={4}>
        <Controller
          name='name'
          rules={{ required: { value: true, message: 'Wymagane' }, maxLength: 128 }}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              autoFocus
              label='Nazwa'
              fullWidth
              variant='standard'
              required
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
            />
          )}
        />
      </Box>

      <Grid container mb={4} alignItems='flex-end' spacing={2}>
        <Grid item xs={6}>
          <Controller
            name='energy'
            rules={{ required: { value: true, message: 'Wymagane' } }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type='number'
                label='kcal/100'
                fullWidth
                variant='standard'
                error={Boolean(errors.energy)}
                helperText={errors.energy?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name='units'
            control={control}
            render={({ field }) => (
              <FormControl fullWidth variant='standard'>
                <InputLabel>Jednostki</InputLabel>
                <Select {...field} fullWidth variant='standard'>
                  <MenuItem value='g'>g</MenuItem>
                  <MenuItem value='ml'>ml</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Grid container mb={4} alignItems='flex-end' spacing={2}>
        <Grid item xs={4}>
          <Controller
            name='protein'
            rules={{ required: { value: true, message: 'Wymagane' } }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type='number'
                label='Białko'
                fullWidth
                variant='standard'
                error={Boolean(errors.protein)}
                helperText={errors.protein?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name='carb'
            rules={{ required: { value: true, message: 'Wymagane' } }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type='number'
                label='Węglowodany'
                fullWidth
                variant='standard'
                error={Boolean(errors.carb)}
                helperText={errors.carb?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name='fat'
            rules={{ required: { value: true, message: 'Wymagane' } }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type='number'
                label='Tłuszcze'
                fullWidth
                variant='standard'
                error={Boolean(errors.fat)}
                helperText={errors.fat?.message}
              />
            )}
          />
        </Grid>
      </Grid>

      <Box mb={4}>
        <Controller
          name={`category`}
          control={control}
          rules={{ required: { value: true, message: 'Wymagane' } }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              fullWidth
              autoHighlight
              getOptionLabel={(option) => option.name || ''}
              options={categories?.rows || []}
              loading={isLoading}
              loadingText='wczytywanie...'
              onChange={(e, category) => changeProductCategory(category, field.onChange)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Kategoria'
                  variant='standard'
                  required
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message} />
              )}
              
            />
          )}
        />
      </Box>

      <ButtonContainer alignButtons='right'>
        <Button variant='contained' disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
          Wyślij
        </Button>
      </ButtonContainer>
    </>
  )
}

export { ProductEditorForm }
