import { ReactNode } from 'react'
import { Box, Drawer, Grid, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'

interface GenericDrawerProps {
  open: boolean
  title: string
  subtitle?: string;
  onClose: () => void
  onEdit?: () => void
  children: ReactNode
}

const GenericDrawer = ({ open, title, subtitle, onEdit, onClose, children }: GenericDrawerProps) => {
  return (
    <Drawer
      open={open}
      onClose={() => onClose()}
      anchor='right'
      PaperProps={{
        sx: { width: '100%', maxWidth: '400px' },
      }}
    >
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='flex-start'
          flexWrap='nowrap'
          mb={2}
        >
          <Grid item width={'auto'}>
            {subtitle && <Typography variant='caption'>{subtitle}</Typography>}
            <Typography variant='h5'>{title}</Typography>
          </Grid>

          <Grid container width={120} flexWrap='nowrap' justifyContent='flex-end'>
            {onEdit && (
              <Grid item width={50} textAlign='right'>
                <IconButton onClick={onEdit}>
                  <EditIcon />
                </IconButton>
              </Grid>
            )}

            <Grid item width={50} textAlign='right'>
              <IconButton onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        {children}
      </Box>
    </Drawer>
  )
}

export { GenericDrawer }
