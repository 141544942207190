import { useEffect, useState } from 'react'
import { Autocomplete, Grid, TextField, Typography } from '@mui/material'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { IProduct } from '../../../../types/IProduct'
import { useQuery } from '@tanstack/react-query'
import { PRODUCTS } from '../../../../constants/query_keys'
import { getProducts } from '../../../../api/products'
import { MealProductFormValues } from '../../../../types/IMeal'
import { getMealMacros, Macros } from '../../../../helpers/meals'

export const initialMealProduct = { product_id: '', product: null, qty: 0 }

export function getInitialMealProducts(mealProducts: MealProductFormValues[] = []) {
  const initialMealProducts: MealProductFormValues[] = mealProducts.map(
    ({ product_id, product, qty }) => ({ product_id, product, qty }),
  )
  initialMealProducts.push(initialMealProduct)

  return initialMealProducts
}

type ProductListFormProps = {
  associationName: 'meal_products' | 'diet_meal_products'
}

const ProductListForm = ({ associationName }: ProductListFormProps) => {
  const { refetch: refetchProducts } = useQuery(
    [PRODUCTS],
    () => getProducts({ limit: 0, page: 0 }),
    { enabled: false },
  )
  const { control, setValue, getValues, watch } = useFormContext()

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<readonly IProduct[]>([])
  const [macros, setMacros] = useState<Macros>(getMealMacros(getValues(associationName)))

  const { fields, append, remove } = useFieldArray({
    name: associationName,
    control,
  })

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name?.startsWith(associationName)) {
        setMacros(getMealMacros(value[associationName]))
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, associationName])

  useEffect(() => {
    async function loadProducts() {
      try {
        setLoading(true)
        const { data } = await refetchProducts()

        if (!data) {
          throw Error('No data')
        }

        setOptions(data.rows)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    loadProducts()
  }, [refetchProducts])

  function changeMealProduct(
    index: number,
    product: IProduct | null,
    fieldOnChange: (...event: any[]) => void,
  ) {
    if (!product && fields.length > 1) {
      remove(index)
      return
    }

    if (index === fields.length - 1) {
      append(initialMealProduct)
    }

    setValue(`${associationName}.${index}.product_id`, product?.id || '')
    fieldOnChange(product)
  }

  const { energy, proteins, fats, carbs } = macros

  return (
    <>
      <Typography variant='caption' paragraph>
        (<strong>K:{energy}</strong> / B:{proteins} / T:{fats} / W:{carbs})
      </Typography>
      {fields.map((field, index) => (
        <Grid key={field.id} container mb={4} alignItems='flex-end' spacing={2}>
          <Grid item xs={9}>
            <Controller
              name={`${associationName}.${index}.product`}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  autoHighlight
                  getOptionLabel={(option) => option.name || ''}
                  options={options}
                  loading={loading}
                  loadingText='wczytywanie...'
                  onChange={(e, product) => changeMealProduct(index, product, field.onChange)}
                  renderInput={(params) => (
                    <TextField {...params} label='Produkt' variant='standard' />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={3}>
            <Controller
              name={`${associationName}.${index}.qty`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type='number'
                  label='Ilość [g]'
                  fullWidth
                  variant='standard'
                />
              )}
            />
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export { ProductListForm }
