import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UIInterface {
  isConfirmVisible: boolean
  title: string
  text?: string
  onConfirm?: (() => void) | null
  onCancel?: (() => void) | null
}

const initialState: UIInterface = {
  isConfirmVisible: false,
  title: '',
}

type PayloadOpenConfirm = Omit<UIInterface, 'isConfirmVisible'>

const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openConfirm: (state, { payload }: PayloadAction<PayloadOpenConfirm>) => {
      return { ...payload, isConfirmVisible: true }
    },
    closeConfirm: (state) => {
      state.isConfirmVisible = false
      state.onCancel = null
      state.onConfirm = null
    },
  },
})

export const uiReducer = ui.reducer

export const { openConfirm, closeConfirm } = ui.actions
