import axios from 'axios'
import qs from 'qs'

import { API_ENDPOINT } from '../constants'
import { GenericGetListResponse, GenericPaginationOptions } from '../types/Api'
import { IMeal, MealFormValues } from '../types/IMeal'

interface GetMealPaginationOptions extends GenericPaginationOptions {
  mealOrder?: number[] | number | null,
  products?: string[] | null
}

export const getMeals = async (options?: GetMealPaginationOptions) => {
  const queryParams = qs.stringify(options)

  const response = await axios.get<GenericGetListResponse<IMeal>>(
    `${API_ENDPOINT}/meals?${queryParams}`,
  )

  return response.data
}

export const countMeals = async (): Promise<number> => {
  const response = await axios.get(`${API_ENDPOINT}/meals/count`)

  return response.data
}

export const getMeal = async (id: string) => {
  const response = await axios.get(`${API_ENDPOINT}/meals/${id}`)

  return response.data
}

export const addMeal = async (body: MealFormValues) => {
  const response = await axios.post(`${API_ENDPOINT}/meals`, body)

  return response.data
}

export const updateMeal = async (body: MealFormValues) => {
  const response = await axios.patch(`${API_ENDPOINT}/meals/${body.id}`, body)

  return response.data
}

export const deleteMeal = async (id: string) => {
  const response = await axios.delete(`${API_ENDPOINT}/meals/${id}`)

  return response.data
}

export const copyMeal = async (body: { id: string; newTitle: string }) => {
  const response = await axios.post(`${API_ENDPOINT}/meals/${body.id}/copy`, body)

  return response.data
}
