import { DataGrid, GridColDef, GridSelectionModel, GridSortModel } from '@mui/x-data-grid'
import { useDispatch } from 'react-redux'

import { IDiet } from '../../../../../types/IDiet'
import { RowActions } from '../../../../Shared/Lists/RowActions'
import { openConfirm } from '../../../../../store/slices/ui'

import './DietList.scss'
import { PAGE_SIZE_DEFAULT } from '../../../../../constants'
import { format } from 'date-fns'

type DietListProps = {
  diets: IDiet[]
  page: number
  count: number
  isLoading?: boolean
  onEdit: (row: IDiet) => void
  onRemove: (id: string) => void
  onCopy: ({ id, title }: { id: string; title: string }) => void
  onRowClick: (row: IDiet) => void
  onRowSelect: (ids: string[]) => void
  onPageChange: (newPage: number) => void
  onSortChange: (props: GridSortModel) => void
}

const DietList = ({
  diets,
  page,
  count,
  isLoading,
  onEdit,
  onRemove,
  onCopy,
  onRowClick,
  onRowSelect,
  onPageChange,
  onSortChange,
}: DietListProps) => {
  const dispatch = useDispatch()

  const columns: GridColDef<IDiet>[] = [
    { field: 'id', hide: true },
    { field: 'title', headerName: 'Nazwa', minWidth: 240, disableColumnMenu: true },
    { field: 'days', headerName: 'Liczba dni', flex: 1, disableColumnMenu: true },
    {
      field: 'start_date',
      headerName: 'Data startu',
      flex: 1,
      disableColumnMenu: true,
      renderCell: ({ row }) => (row.start_date ? format(new Date(row.start_date), 'd MMMM') : null),
    },
    {
      field: 'action',
      type: 'actions',
      sortable: false,
      width: 180,
      align: 'right',
      renderCell: ({ row }) => (
        <RowActions<IDiet>
          row={row}
          onEdit={onEdit}
          onRemove={confirmRemove}
          onCopy={(id) => onCopy({ id, title: row.title })}
        />
      ),
    },
  ]

  function confirmRemove(productId: string) {
    dispatch(
      openConfirm({
        title: 'Usuń produkt',
        text: 'To spowoduje usunięcie całej diety. Czy aby na pewno?',
        onConfirm: () => onRemove(productId),
      }),
    )
  }

  return (
    <DataGrid
      columns={columns}
      rows={diets}
      rowCount={count}
      loading={isLoading}
      disableSelectionOnClick
      checkboxSelection
      autoHeight
      pagination
      page={page}
      pageSize={PAGE_SIZE_DEFAULT}
      paginationMode='server'
      sortingMode='server'
      onRowClick={({ row }) => onRowClick(row)}
      onSelectionModelChange={(ids: GridSelectionModel) => onRowSelect(ids as string[])}
      className='DietList'
      onPageChange={onPageChange}
      onSortModelChange={onSortChange}
      initialState={{
        sorting: {
          sortModel: [{ field: 'title', sort: 'asc' }],
        },
      }}
    />
  )
}

export { DietList }
