import axios from 'axios'
import qs from 'qs'
import { API_ENDPOINT } from '../constants'
import { GenericGetListResponse, GenericPaginationOptions } from '../types/Api'
import { IProduct, ProductFormValues } from '../types/IProduct'
import { AxiosApiService } from './api_service'

export const getProducts = async (
  options?: GenericPaginationOptions,
): Promise<GenericGetListResponse<IProduct>> => {
  const queryString = qs.stringify(options)

  const response = await axios.get(`${API_ENDPOINT}/products?${queryString}`)

  return response.data
}

export const countProducts = async (): Promise<number> => {
  const response = await axios.get(`${API_ENDPOINT}/products/count`)

  return response.data
}

export const addProduct = async (body: ProductFormValues) => {
  return AxiosApiService.postAndToastError<ProductFormValues>(`${API_ENDPOINT}/products`, body)
}

export const updateProduct = async (body: ProductFormValues) => {
  const response = await axios.patch(`${API_ENDPOINT}/products/${body.id}`, body)

  return response.data
}

export const deleteProduct = async (id: string) => {
  const response = await axios.delete(`${API_ENDPOINT}/products/${id}`)

  return response.data
}
