import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

import {
  addDiet,
  copyDiet,
  deleteDiet,
  exportShoppingList,
  getDiets,
  updateDiet,
} from '../../../api/diets'
import { DIETS } from '../../../constants/query_keys'
import { Content } from '../../Layout'
import { DietEditorDrawer } from './Components/DietEditorDrawer'
import { DietList } from './Components/DietList'
import { IDiet, DietFormValues } from '../../../types/IDiet'
import { CopyDialog } from '../../Shared/Dialogs/CopyDialog'
import { toast } from 'react-toastify'
import { usePagination, useSorting } from '../../../hooks'
import { PAGE_SIZE_DEFAULT } from '../../../constants'

const Diets = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { page, setPage } = usePagination()
  const { field, order, setSort } = useSorting('title')

  const { isLoading, error, data } = useQuery(
    [DIETS, page, field, order],
    () => getDiets({ limit: PAGE_SIZE_DEFAULT, page, field, order, include: ['products'] }),
    { keepPreviousData: true },
  )
  const mutationCreate = useMutation(addDiet)
  const mutationUpdate = useMutation(updateDiet)
  const mutationDelete = useMutation(deleteDiet)

  const [editorOpen, setEditorOpen] = useState(false)
  const [selectedDiet, setSelectedDiet] = useState<IDiet | null>(null)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [selectedCopy, setSelectedCopy] = useState<{ title: string; id: string } | null>(null)

  function openEditor(meal?: IDiet) {
    setSelectedDiet(meal || null)
    setEditorOpen(true)
  }
  function closeEditor() {
    setSelectedDiet(null)
    setEditorOpen(false)
  }

  async function create(values: DietFormValues) {
    try {
      await mutationCreate.mutateAsync(values)
      queryClient.invalidateQueries([DIETS])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  async function update(values: DietFormValues) {
    try {
      await mutationUpdate.mutateAsync(values)
      queryClient.invalidateQueries([DIETS])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  async function remove(id: string) {
    try {
      await mutationDelete.mutateAsync(id)
      queryClient.invalidateQueries([DIETS])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  async function copy({ name }: { name: string }) {
    if (!selectedCopy) {
      return
    }

    try {
      await copyDiet({ id: selectedCopy.id, newTitle: name })
      queryClient.invalidateQueries([DIETS])
      toast.success('Dieta została skopiowana.')
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  async function exportList() {
    const list = await exportShoppingList({ diet_ids: selectedRows })
    navigate(`/diets/shopping_list`, { state: list })
  }

  function detailsRedirect(diet: IDiet) {
    navigate(`/diets/${diet.id}`)
  }

  return (
    <Content isLoading={isLoading} error={error as Error}>
      <>
        <Box mb={3} display='flex' justifyContent='flex-end'>
          <Button variant='contained' onClick={() => openEditor()}>
            Stwórz dietę
          </Button>
        </Box>

        {selectedRows.length > 0 && (
          <Box mb={2}>
            <Button startIcon={<FileDownloadIcon />} onClick={exportList}>
              Eksportuj listę zakupów
            </Button>
          </Box>
        )}

        {data && (
          <DietList
            diets={data.rows}
            count={data.count}
            page={page}
            isLoading={isLoading}
            onEdit={openEditor}
            onRemove={remove}
            onCopy={setSelectedCopy}
            onRowClick={detailsRedirect}
            onRowSelect={setSelectedRows}
            onPageChange={setPage}
            onSortChange={(sortItem) => setSort(sortItem[0].field, sortItem[0].sort)}
          />
        )}

        <DietEditorDrawer
          open={editorOpen}
          diet={selectedDiet}
          onCreate={create}
          onUpdate={update}
          onClose={closeEditor}
        />

        {selectedCopy && (
          <CopyDialog
            defaultName={selectedCopy.title}
            onCopy={copy}
            onClose={() => setSelectedCopy(null)}
          />
        )}
      </>
    </Content>
  )
}

export { Diets }
