import { ProductFormValues } from '../../../../../types/IProduct'
import { ProductEditorForm } from './ProductEditorForm'
import { GenericDrawer } from '../../../../Shared/Lists/GenericDrawer'

type ProductEditorDrawerProps = {
  open: boolean
  product?: ProductFormValues | null
  onUpdate: (v: ProductFormValues) => void
  onCreate: (v: ProductFormValues) => void
  onClose: () => void
}

const ProductEditorDrawer = ({
  open,
  product,
  onCreate,
  onUpdate,
  onClose,
}: ProductEditorDrawerProps) => {
  async function submit(values: ProductFormValues) {
    try {
      values.id ? await onUpdate(values) : await onCreate(values)
      onClose()
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  return (
    <GenericDrawer open={open} onClose={onClose} title='Dodaj produkt'>
      {open && <ProductEditorForm product={product} onSubmit={submit} />}
    </GenericDrawer>
  )
}

export { ProductEditorDrawer }
