import React from 'react'
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import SetMealIcon from '@mui/icons-material/SetMeal'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import MenuBookIcon from '@mui/icons-material/MenuBook'

import './Sidebar.scss'

export const pages = [
  { path: '/', title: 'Pulpit', IconComponent: HomeIcon },
  { path: '/products', title: 'Produkty', IconComponent: SetMealIcon },
  { path: '/meals', title: 'Posiłki', IconComponent: RestaurantIcon },
  { path: '/diets', title: 'Diety', IconComponent: MenuBookIcon },
]

interface SidebarProps {
  open?: boolean
  setOpen: (v: boolean) => void
}

const Sidebar = ({ open, setOpen }: SidebarProps) => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))

  function ListItemLink(props: { icon: React.ReactNode; primary: string; to: string }) {
    const { icon, primary, to } = props

    const renderLink = React.useMemo(
      () =>
        React.forwardRef<HTMLAnchorElement, Omit<any, 'to'>>(function Link(itemProps, ref) {
          return (
            <RouterLink
              to={to}
              ref={ref}
              {...itemProps}
              role={undefined}
              onClick={() => setOpen(false)}
            />
          )
        }),
      [to],
    )

    return (
      <li>
        <ListItem button component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItem>
      </li>
    )
  }

  return (
    <Drawer
      className='Sidebar'
      open={open}
      onClose={() => setOpen(false)}
      variant={isLargeScreen ? 'permanent' : 'temporary'}
      anchor='left'
    >
      <Toolbar />
      <Divider />
      <List>
        {pages.map(({ path, title, IconComponent }) => (
          <React.Fragment key={path}>
            <ListItemLink to={path} primary={title} icon={<IconComponent />} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  )
}

export { Sidebar }
