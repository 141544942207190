import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  Paper,
  TableRow,
  Typography,
} from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import {
  addDietDay,
  addDietMeal,
  deleteDietDay,
  deleteDietMeal,
  getDiet,
  toggleDietDay,
  updateDietMeal,
} from '../../../api/diets'
import { DIETS } from '../../../constants/query_keys'
import { useToggler } from '../../../hooks'
import { DietMealFormValues, IDietMeal } from '../../../types/IDietMeal'
import { Content } from '../../Layout'
import { ButtonContainer } from '../../Shared/Buttons/ButtonContainer'
import { DietMealEditorDrawer } from './Components/DietMealEditorDrawer'
import { DietMealViewerDrawer } from './Components/DietMealViewerDrawer'
import { DietRow } from './Components/DietRow'
import { IDiet } from '../../../types/IDiet'

type RouteParams = {
  dietId: string
}

const DietDetails = () => {
  const { dietId } = useParams<keyof RouteParams>() as RouteParams
  const queryClient = useQueryClient()
  const { data: diet, error, isLoading } = useQuery([DIETS, dietId], () => getDiet(dietId))

  const mutationDaysAdd = useMutation(addDietDay)
  const mutationDaysDelete = useMutation(deleteDietDay)
  const mutationMealAdd = useMutation(addDietMeal)
  const mutationMealUpdate = useMutation(updateDietMeal)
  const mutationMealDelete = useMutation(deleteDietMeal)
  const mutationDayToggle = useMutation(toggleDietDay)

  const {
    isOpen: isMealEditorOpen,
    open: openMealEditor,
    close: closeMealEditor,
    selectedItem: editorMeal,
  } = useToggler<DietMealFormValues>()

  const {
    isOpen: isMealViewerOpen,
    open: openMealViewer,
    close: closeMealViewer,
    selectedItem: viewerMeal,
  } = useToggler<IDietMeal>()

  function openMealEditorFromViewer(dietMeal: IDietMeal) {
    closeMealViewer()
    openMealEditor(dietMeal)
  }

  async function removeDay() {
    try {
      await mutationDaysDelete.mutateAsync(dietId)
      queryClient.invalidateQueries([DIETS, dietId])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  async function addDay() {
    try {
      await mutationDaysAdd.mutateAsync(dietId)
      queryClient.invalidateQueries([DIETS, dietId])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  async function addMeal(dietMeal: DietMealFormValues) {
    try {
      await mutationMealAdd.mutateAsync(dietMeal)
      queryClient.invalidateQueries([DIETS, dietId])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  async function updateMeal(dietMeal: DietMealFormValues) {
    try {
      await mutationMealUpdate.mutateAsync(dietMeal)
      queryClient.invalidateQueries([DIETS, dietId])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  async function removeMeal(id: string) {
    try {
      await mutationMealDelete.mutateAsync({ dietId, dietMealId: id })
      queryClient.invalidateQueries([DIETS, dietId])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  async function toggleDay(day: number, active: boolean) {
    try {
      await mutationDayToggle.mutateAsync({ dietId, day, active })
      queryClient.invalidateQueries([DIETS, dietId])
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const days = Array(diet?.days || [])
    .fill(0)
    .map((day, index) => index + 1)

  const mealsByDay: Record<number, IDietMeal[]> = days.reduce((acc, day) => {
    return { ...acc, [day]: [] }
  }, {})

  for (const dietMeal of diet?.diet_meals || []) {
    for (const day of dietMeal.days) {
      mealsByDay[day].push(dietMeal)
    }
  }

  return (
    <Content isLoading={isLoading} error={error as Error}>
      <>
        {diet && (
          <>
            <Typography variant='h5' gutterBottom>
              {diet.title}
            </Typography>
            <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell align='right'>Kalorie [kcal]</TableCell>
                    <TableCell align='right' sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                      B [g]
                    </TableCell>
                    <TableCell align='right' sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                      W [g]
                    </TableCell>
                    <TableCell align='right' sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                      T [g]
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {days.map((day) => (
                    <DietRow
                      key={day}
                      day={day}
                      startDate={diet.start_date}
                      isLastDay={day === diet.days}
                      isDisabled={diet.disabled_days.includes(day)}
                      meals={mealsByDay[day] || []}
                      onAddMeal={openMealEditor}
                      onEditMeal={openMealEditor}
                      onRemoveMeal={removeMeal}
                      onRemove={removeDay}
                      onSelect={openMealViewer}
                      onDayActivation={toggleDay}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        <ButtonContainer alignButtons='right'>
          <Button variant='contained' onClick={addDay}>
            Dodaj kolejny dzień
          </Button>
        </ButtonContainer>

        <DietMealEditorDrawer
          open={isMealEditorOpen}
          onCreate={addMeal}
          onUpdate={updateMeal}
          onClose={closeMealEditor}
          dietMeal={editorMeal}
          days={days}
        />

        {!isLoading && <DietMealViewerDrawer
          open={isMealViewerOpen}
          diet={diet || {} as IDiet}
          dietMealId={viewerMeal?.id}
          onEdit={openMealEditorFromViewer}
          onClose={closeMealViewer}
        />}
      </>
    </Content>
  )
}

export { DietDetails }
