import { Box, Button, TextField, TextFieldProps } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { startOfDay } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'

import { IDiet } from '../../../../../types/IDiet'
import { ButtonContainer } from '../../../../Shared/Buttons/ButtonContainer'

type DietEditorFormProps = {
  diet?: IDiet | null
  onSubmit: (v: IDiet) => void
}

const DatePickerTextField = (props: TextFieldProps) => {
  return <TextField {...props} fullWidth variant='standard' />
}

const DietEditorForm = ({ diet, onSubmit }: DietEditorFormProps) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IDiet>({
    defaultValues: {
      id: diet?.id,
      title: diet?.title || '',
      description: diet?.description || '',
      days: diet?.days || 7,
      start_date: diet?.start_date ? new Date(diet.start_date) : null,
    },
  })

  return (
    <>
      <Box mb={4}>
        <Controller
          name='title'
          rules={{ required: { value: true, message: 'Wymagane' }, maxLength: 128 }}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              autoFocus
              label='Nazwa'
              fullWidth
              variant='standard'
              required
              error={Boolean(errors.title)}
              helperText={errors.title?.message}
            />
          )}
        />
      </Box>

      <Box mb={4}>
        <Controller
          name='start_date'
          control={control}
          render={({ field }) => (
            <DatePicker
              {...field}
              onChange={(date) => setValue('start_date', date ? startOfDay(date) : null)}
              label='Data startu'
              format='dd / MM / yyyy'
              slots={{
                textField: DatePickerTextField,
              }}
            />
          )}
        />
      </Box>

      <Box mb={4}>
        <Controller
          name='description'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              rows={2}
              maxRows={2}
              label='Opis'
              fullWidth
              variant='standard'
            />
          )}
        />
      </Box>

      <ButtonContainer alignButtons='right'>
        <Button variant='contained' disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
          Wyślij
        </Button>
      </ButtonContainer>
    </>
  )
}

export { DietEditorForm }
