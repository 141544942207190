import { useState } from 'react'

function useToggler<T extends unknown>(initialState?: boolean) {
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(initialState))
  const [selectedItem, setSelectedItem] = useState<T | null>(null)

  function open(item?: T) {
    setSelectedItem(item || null)
    setIsOpen(true)
  }
  function close() {
    setSelectedItem(null)
    setIsOpen(false)
  }

  return { isOpen, open, close, selectedItem }
}

export { useToggler }
