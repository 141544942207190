import axios from 'axios'
import { API_ENDPOINT } from '../constants'
import { GenericGetListResponse } from '../types/Api'
import { ICategory } from '../types/ICategory'

export const getCategories = async (): Promise<GenericGetListResponse<ICategory>> => {
  const response = await axios.get(`${API_ENDPOINT}/categories`)

  return response.data
}
