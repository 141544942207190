import { GridSortDirection } from '@mui/x-data-grid'
import { useState } from 'react'

function useSorting(defaultField: string = '', defaultOrder: GridSortDirection = 'asc') {
  const [field, setField] = useState<string>(defaultField)
  const [order, setOrder] = useState<GridSortDirection>(defaultOrder)

  function setSort(newField: string, newOrder: GridSortDirection) {
    setField(newField)
    setOrder(newOrder)
  }

  return { field, order, setSort }
}

export { useSorting }
