import { IDiet } from '../../../../../types/IDiet'
import { GenericDrawer } from '../../../../Shared/Lists/GenericDrawer'
import { DietEditorForm } from './DietEditorForm'

type DietEditorDrawerProps = {
  open: boolean
  diet?: IDiet | null
  onUpdate: (v: IDiet) => void
  onCreate: (v: IDiet) => void
  onClose: () => void
}

const DietEditorDrawer = ({ open, diet, onCreate, onUpdate, onClose }: DietEditorDrawerProps) => {
  async function submit(values: IDiet) {
    try {
      values.id ? await onUpdate(values) : await onCreate(values)
      onClose()
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  return (
    <GenericDrawer open={open} onClose={onClose} title={diet ? 'Opcje diety' : 'Stwórz nową dietę'}>
      {open && <DietEditorForm diet={diet} onSubmit={submit} />}
    </GenericDrawer>
  )
}

export { DietEditorDrawer }
