import { ChangeEvent } from 'react'
import { debounce } from 'lodash'
import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

interface SearchFieldProps {
  onChange: (v: string) => void
}

const SearchField = ({ onChange }: SearchFieldProps) => {
  const onChangeDebounced = debounce((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }, 400)

  return (
    <TextField
      autoFocus
      fullWidth
      size='small'
      placeholder='Szukaj...'
      variant='outlined'
      InputProps={{
        endAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={onChangeDebounced}
    />
  )
}

export { SearchField }
