import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

type CopyDialogProps = {
  defaultName: string
  onCopy: (v: FormValues) => void
  onClose: () => void
}

type FormValues = {
  name: string
}

const CopyDialog = ({ defaultName, onClose, onCopy }: CopyDialogProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      name: defaultName,
    },
  })

  async function handleCopy({ name }: FormValues) {
    await onCopy({ name })
    onClose()
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Utwórz kopię</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Podaj nazwę dla kopiowanego elementu. Nazwa musi być inna niż oryginału.
        </DialogContentText>
        <Controller
          name='name'
          rules={{
            required: { value: true, message: 'Wymagane' },
            validate: (value) => value !== defaultName || 'Nazwa musi być unikalna',
            maxLength: 128,
          }}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              autoFocus
              margin='normal'
              label='Nazwa'
              fullWidth
              variant='standard'
              required
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
            />
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={isSubmitting} onClick={onClose}>
          Anuluj
        </Button>
        <Button disabled={isSubmitting} onClick={handleSubmit(handleCopy)}>
          Kopiuj
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { CopyDialog }
