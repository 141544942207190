import { IDietMeal } from '../types/IDietMeal'
import { IDietMealProduct } from '../types/IDietMealProduct'
import { IMealProduct } from '../types/IMealProduct'

type MealProduct = Pick<IMealProduct | IDietMealProduct, 'product' | 'qty'>

export type Macros = {
  energy: string
  proteins: string
  fats: string
  carbs: string
}

function _calculateMealMacros(mealProducts: MealProduct[]) {
  return mealProducts.reduce(
    (acc: any, { product, qty }) => {
      if (!product) {
        return acc
      }

      return {
        energy: acc.energy + (product.energy * qty) / 100,
        proteins: acc.proteins + (product.protein * qty) / 100,
        fats: acc.fats + (product.fat * qty) / 100,
        carbs: acc.carbs + (product.carb * qty) / 100,
      }
    },
    { energy: 0, proteins: 0, fats: 0, carbs: 0 },
  )
}

export function getMealMacros(mealProducts: MealProduct[]) {
  const macrosNumbers = _calculateMealMacros(mealProducts)

  return {
    energy: macrosNumbers.energy.toFixed(0),
    proteins: macrosNumbers.proteins.toFixed(0),
    fats: macrosNumbers.fats.toFixed(0),
    carbs: macrosNumbers.carbs.toFixed(0),
  }
}

export function getMealsMacros(meals: IDietMeal[]) {
  const macrosTotalNumbers = meals.reduce(
    (acc, { diet_meal_products }) => {
      const mealMacros = _calculateMealMacros(diet_meal_products)

      return {
        energy: acc.energy + mealMacros.energy,
        proteins: acc.proteins + mealMacros.proteins,
        fats: acc.fats + mealMacros.fats,
        carbs: acc.carbs + mealMacros.carbs,
      }
    },
    { energy: 0, proteins: 0, fats: 0, carbs: 0 },
  )

  return {
    energy: macrosTotalNumbers.energy.toFixed(0),
    proteins: macrosTotalNumbers.proteins.toFixed(0),
    fats: macrosTotalNumbers.fats.toFixed(0),
    carbs: macrosTotalNumbers.carbs.toFixed(0),
  }
}
