import axios from 'axios'
import qs from 'qs'

import { USDA_API_KEY, USDA_API_ENDPOINT } from '../constants'
import { IProduct, ProductFormValues } from '../types/IProduct'

const UsdaNutrient = {
  protein: 1003,
  fat: 1004,
  carb: 1005,
  energy: 1008,
}

function getFoodMacros(foodNutrients: any[]) {
  const macros: Pick<IProduct, 'protein' | 'carb' | 'fat' | 'energy'> = {
    protein: 0,
    fat: 0,
    carb: 0,
    energy: 0,
  }

  for (const nutrient of foodNutrients) {
    if (nutrient.nutrientId === UsdaNutrient.protein) {
      macros.protein = nutrient.value
    }

    if (nutrient.nutrientId === UsdaNutrient.fat) {
      macros.fat = nutrient.value
    }

    if (nutrient.nutrientId === UsdaNutrient.carb) {
      macros.carb = nutrient.value
    }

    if (nutrient.nutrientId === UsdaNutrient.energy) {
      macros.energy = nutrient.value
    }
  }

  return macros
}

export const searchFood = async (searchValue: string) => {
  const queryParams = qs.stringify({
    query: searchValue,
    pageSize: 1,
    api_key: USDA_API_KEY,
  })

  const response = await axios.get(`${USDA_API_ENDPOINT}/v1/foods/search?${queryParams}`)

  if (!response.data.totalHits) {
    return {
      hits: 0,
      product: null,
    }
  }

  const food = response.data.foods[0]

  const product: ProductFormValues = {
    name: food.description,
    fdc_id: food.fdcId,
    units: 'g',
    ...getFoodMacros(food.foodNutrients),
  }

  return {
    hits: response.data.totalHits,
    product,
  }
}
