import { Autocomplete, TextField } from '@mui/material'
import { MEAL_ORDER, MEAL_ORDER_OPTIONS } from '../../../../constants/meals'

interface SearchFieldProps {
  onChange: (v: number[] | null) => void
}

const SuggestedOrderField = ({ onChange }: SearchFieldProps) => {
  return (
    <Autocomplete
      fullWidth
      limitTags={2}
      size='small'
      multiple
      getOptionLabel={(option) => MEAL_ORDER[option] || ''}
      options={MEAL_ORDER_OPTIONS}
      onChange={(e, option) => onChange(option)}
      renderInput={(params) => (
        <TextField {...params} label='Pora posiłku...' variant='outlined' size='small' />
      )}
    />
  )
}

export { SuggestedOrderField }
