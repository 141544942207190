import { Autocomplete, TextField } from '@mui/material'
import { MEAL_ORDER, MEAL_ORDER_OPTIONS } from '../../../../constants/meals'
import { useQuery } from '@tanstack/react-query'
import { PRODUCTS } from '../../../../constants/query_keys'
import { getProducts } from '../../../../api/products'
import { IProduct } from '../../../../types/IProduct'

interface SearchFieldProps {
  onChange: (v: string[]) => void
}

const SelectProductsField = ({ onChange }: SearchFieldProps) => {
  const { data, isFetching } = useQuery(
    [PRODUCTS],
    () => getProducts({ limit: 0, page: 0 }),
  )

  return (
    <Autocomplete
      fullWidth
      limitTags={2}
      size='small'
      multiple
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      options={data?.rows || []}
      onChange={(e, option) => onChange(option.map(o => o.id))}
      loading={isFetching}
      renderInput={(params) => (
        <TextField {...params} label='Zawierające produkt...' variant='outlined' size='small' />
      )}
    />
  )
}

export { SelectProductsField }
