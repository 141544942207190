import { useState } from 'react'

import { Sidebar } from './Sidebar'
import { Topbar } from './Topbar'

const Navigation = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <>
      <Topbar onMenuToggle={() => setIsDrawerOpen(!isDrawerOpen)} />
      <Sidebar open={isDrawerOpen} setOpen={setIsDrawerOpen} />
    </>
  )
}

export { Navigation }
