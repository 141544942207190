import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid'
import { useDispatch } from 'react-redux'

import { IProduct } from '../../../../../types/IProduct'
import { RowActions } from '../../../../Shared/Lists/RowActions'
import { openConfirm } from '../../../../../store/slices/ui'
import { PAGE_SIZE_DEFAULT } from '../../../../../constants'

type ProductListProps = {
  products: IProduct[]
  isLoading?: boolean
  page: number
  count: number
  onPageChange: (newPage: number) => void
  onSortChange: (props: GridSortModel) => void
  onEdit: (row: IProduct) => void
  onRemove: (id: string) => void
}

const ProductList = ({
  products,
  page,
  count,
  isLoading,
  onPageChange,
  onSortChange,
  onEdit,
  onRemove,
}: ProductListProps) => {
  const dispatch = useDispatch()

  const columns: GridColDef<IProduct>[] = [
    { field: 'id', hide: true },
    { field: 'name', headerName: 'Nazwa', minWidth: 240, disableColumnMenu: true },
    {
      field: 'energy',
      headerName: 'Energia [kcal]',
      type: 'number',
      minWidth: 120,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'protein',
      headerName: 'Białko [g]',
      type: 'number',
      minWidth: 120,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'fat',
      headerName: 'Tłuszcze [g]',
      type: 'number',
      minWidth: 120,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'carb',
      headerName: 'Węglowodany [g]',
      type: 'number',
      minWidth: 120,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'category',
      headerName: 'Kategoria',
      minWidth: 150,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.category?.name}</>,
    },
    {
      field: 'action',
      type: 'actions',
      sortable: false,
      renderCell: ({ row }) => (
        <RowActions<IProduct> row={row} onEdit={onEdit} onRemove={confirmRemove} />
      ),
    },
  ]

  function confirmRemove(productId: string) {
    dispatch(
      openConfirm({
        title: 'Usuń produkt',
        text: 'Czy na pewno usunąć ten produkt?',
        onConfirm: () => onRemove(productId),
      }),
    )
  }

  return (
    <DataGrid
      columns={columns}
      rows={products}
      rowCount={count}
      loading={isLoading}
      disableSelectionOnClick
      autoHeight
      pagination
      page={page}
      pageSize={PAGE_SIZE_DEFAULT}
      paginationMode='server'
      sortingMode='server'
      onPageChange={onPageChange}
      onSortModelChange={onSortChange}
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        },
      }}
    />
  )
}

export { ProductList }
