import { CircularProgress, Grid } from '@mui/material'

const Spinner = () => {
  return (
    <Grid container my={4} justifyContent='center'>
      <CircularProgress />
    </Grid>
  )
}

export { Spinner }
