import CssBaseline from '@mui/material/CssBaseline'
import { Box } from '@mui/material'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Dashboard } from './components/Pages/Dashboard'
import { Products } from './components/Pages/Products'
import { Meals } from './components/Pages/Meals'
import { Diets } from './components/Pages/Diets'
import { DietDetails } from './components/Pages/DietDetails'
import { DietShoppingList } from './components/Pages/DietShoppingList'
import { Navigation } from './components/Layout/Navigation'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { pl } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns'

setDefaultOptions({ locale: pl })

const queryClient = new QueryClient()

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <ToastContainer position='top-center' pauseOnHover />

          <Router>
            <Navigation />

            <Routes>
              <Route path='/' element={<Dashboard />} />
              <Route path='products/*' element={<Products />} />
              <Route path='meals/*' element={<Meals />} />
              <Route path='diets' element={<Diets />} />
              <Route path='diets/shopping_list' element={<DietShoppingList />} />
              <Route path='diets/:dietId' element={<DietDetails />} />
            </Routes>
          </Router>
        </Box>
      </LocalizationProvider>
    </QueryClientProvider>
  )
}

export { App }
