import { Alert, Box, Toolbar } from '@mui/material'
import { ConfirmDialog } from '../Shared/Dialogs/ConfirmDialog'
import { Spinner } from '../Shared/Loaders'

interface ContentProps {
  isLoading?: boolean
  error?: Error
  children: JSX.Element
}

const Content = ({ isLoading, error, children }: ContentProps) => {
  return (
    <>
      <Box component='main' width='100%' sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
        <Toolbar />

        {isLoading && <Spinner />}

        {error && <Alert severity='error'>{error?.message || ''}</Alert>}

        {!isLoading && !error && <>{children}</>}
      </Box>

      <ConfirmDialog />
    </>
  )
}

export { Content }
