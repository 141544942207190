import { Box, Grid, Typography } from '@mui/material'
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen'

import { getMealMacros } from '../../../helpers/meals'
import { IDietMeal } from '../../../types/IDietMeal'
import { IMeal } from '../../../types/IMeal'

interface MealOverviewProps {
  meal: IMeal | IDietMeal
}

const MealOverview = ({ meal }: MealOverviewProps) => {
  const mealProducts = 'meal_products' in meal ? meal.meal_products : meal.diet_meal_products

  const { energy, proteins, fats, carbs } = getMealMacros(mealProducts)

  const daysMultiplier = 'days' in meal ? meal.days.length : 1
  const days = 'days' in meal ? meal.days : []

  return (
    <>
      {meal.description && (
        <Box mb={4}>
          <Typography variant='overline' gutterBottom>
            Opis
          </Typography>
          <Typography sx={{ whiteSpace: 'pre-line' }}>{meal.description}</Typography>
        </Box>
      )}

      <Box mb={4}>
        <Typography variant='overline' gutterBottom>
          Składniki{' '}
          {daysMultiplier > 1 && days.map((d) => <SoupKitchenIcon key={d} fontSize='small' />)}
        </Typography>

        {mealProducts.map(({ id, product, qty }) => (
          <Grid key={id} container justifyContent='space-between'>
            <Typography>{product?.name}</Typography>
            <Typography>{`${qty * daysMultiplier} g`}</Typography>
          </Grid>
        ))}
      </Box>

      <Box mb={4}>
        <Typography variant='overline' gutterBottom>
          Podsumowanie
        </Typography>

        <Grid container justifyContent='space-between'>
          <Typography>Energia</Typography>
          <Typography>{`${energy} kcal`}</Typography>
        </Grid>

        <Grid container justifyContent='space-between'>
          <Typography>Białka</Typography>
          <Typography>{`${proteins} g`}</Typography>
        </Grid>

        <Grid container justifyContent='space-between'>
          <Typography>Tłuszcze</Typography>
          <Typography>{`${fats} g`}</Typography>
        </Grid>

        <Grid container justifyContent='space-between'>
          <Typography>Węglowodany</Typography>
          <Typography>{`${carbs} g`}</Typography>
        </Grid>
      </Box>
    </>
  )
}

export { MealOverview }
