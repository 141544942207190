import { DietMealFormValues } from '../../../../../types/IDietMeal'
import { GenericDrawer } from '../../../../Shared/Lists/GenericDrawer'
import { DietMealEditorForm } from './DietMealEditorForm'

interface DietMealEditorDrawerProps {
  open: boolean
  dietMeal: DietMealFormValues | null
  days: number[]
  onCreate: (v: DietMealFormValues) => void
  onUpdate: (v: DietMealFormValues) => void
  onClose: () => void
}

const DietMealEditorDrawer = ({
  open,
  dietMeal,
  days,
  onCreate,
  onUpdate,
  onClose,
}: DietMealEditorDrawerProps) => {
  async function submit(values: DietMealFormValues) {
    try {
      dietMeal?.id ? await onUpdate(values) : await onCreate(values)
      onClose()
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  return (
    <GenericDrawer
      open={open}
      onClose={onClose}
      title={dietMeal?.id ? 'Edytuj posiłek' : 'Dodaj posiłek'}
    >
      {dietMeal && <DietMealEditorForm dietMeal={dietMeal} days={days} onSubmit={submit} />}
    </GenericDrawer>
  )
}

export { DietMealEditorDrawer }
