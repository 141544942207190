import { useEffect, useState } from 'react'
import { PAGE_SIZE_DEFAULT } from '../constants'

function usePagination() {
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(PAGE_SIZE_DEFAULT)
  const [offset, setOffset] = useState(page * limit)

  useEffect(() => {
    setOffset(page * limit)
  }, [page, limit])

  return { page, setPage, limit, setLimit, offset }
}

export { usePagination }
