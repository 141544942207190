import { Autocomplete, Box, Button, TextField } from '@mui/material'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { ButtonContainer } from '../../../../Shared/Buttons/ButtonContainer'
import { IMeal, MealFormValues } from '../../../../../types/IMeal'
import { getInitialMealProducts, ProductListForm } from '../../../../Shared/Forms/ProductListForm'
import { MEAL_ORDER, MEAL_ORDER_OPTIONS } from '../../../../../constants/meals'

type MealEditorProps = {
  meal?: IMeal | null
  onSubmit: (v: MealFormValues) => void
}

const MealEditorForm = ({ meal, onSubmit }: MealEditorProps) => {
  const formMethods = useForm<MealFormValues>({
    defaultValues: {
      id: meal?.id,
      title: meal?.title || '',
      description: meal?.description || '',
      suggested_order: meal?.suggested_order || 0,
      meal_products: getInitialMealProducts(meal?.meal_products),
    },
  })

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = formMethods

  function prepareSubmitData(values: MealFormValues) {
    const meal_products = values.meal_products
      // remove all empty selections
      .filter(({ product_id }) => product_id)
      // product object is not needed because we already set product_id
      .map(({ qty, product_id }) => ({ qty, product_id, meal_id: meal?.id }))

    onSubmit({ ...values, meal_products })
  }

  return (
    <FormProvider {...formMethods}>
      <Box mb={4}>
        <Controller
          name='title'
          rules={{ required: { value: true, message: 'Wymagane' }, maxLength: 128 }}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              autoFocus
              label='Nazwa'
              fullWidth
              variant='standard'
              required
              error={Boolean(errors.title)}
              helperText={errors.title?.message}
            />
          )}
        />
      </Box>

      <Box mb={4}>
        <Controller
          name='description'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              rows={4}
              maxRows={4}
              label='Dodatkowy opis'
              fullWidth
              variant='standard'
            />
          )}
        />
      </Box>

      <Box mb={4}>
        <Controller
          name='suggested_order'
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              fullWidth
              getOptionLabel={(option) => MEAL_ORDER[option] || ''}
              options={MEAL_ORDER_OPTIONS}
              onChange={(e, option) => setValue('suggested_order', option)}
              renderInput={(params) => (
                <TextField {...params} label='Sugerowana pora posiłku' variant='standard' />
              )}
            />
          )}
        />
      </Box>

      <ProductListForm associationName='meal_products' />

      <ButtonContainer alignButtons='right'>
        <Button
          variant='contained'
          disabled={isSubmitting}
          onClick={handleSubmit(prepareSubmitData)}
        >
          Wyślij
        </Button>
      </ButtonContainer>
    </FormProvider>
  )
}

export { MealEditorForm }
