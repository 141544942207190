import { useQuery } from '@tanstack/react-query'

import { getDietMeal } from '../../../../../api/diets'
import { MEALS } from '../../../../../constants/query_keys'
import { IDietMeal } from '../../../../../types/IDietMeal'
import { GenericDrawer } from '../../../../Shared/Lists/GenericDrawer'
import { Spinner } from '../../../../Shared/Loaders'
import { MealOverview } from '../../../../Shared/MealOverview'
import { IDiet } from '../../../../../types/IDiet'

type DietMealViewerDrawerProps = {
  open: boolean
  diet: IDiet
  dietMealId?: string
  onEdit: (meal: IDietMeal) => void
  onClose: () => void
}

const DietMealViewerDrawer = ({
  open,
  diet,
  dietMealId = '',
  onEdit,
  onClose,
}: DietMealViewerDrawerProps) => {
  const { isLoading, data: meal } = useQuery(
    [MEALS, diet.id, dietMealId],
    () => getDietMeal({ dietId: diet.id, dietMealId }),
    {
      enabled: Boolean(dietMealId),
    },
  )

  return (
    <GenericDrawer
      open={open}
      onEdit={() => onEdit(meal)}
      onClose={onClose}
      title={meal?.title || ''}
      subtitle={diet?.title || ''}
    >
      {isLoading && <Spinner />}
      {!isLoading && open && meal && <MealOverview meal={meal} />}
    </GenericDrawer>
  )
}

export { DietMealViewerDrawer }
