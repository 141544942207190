import axios from 'axios'
import qs from 'qs'

import { API_ENDPOINT } from '../constants'
import { GenericGetListResponse, GenericPaginationOptions } from '../types/Api'
import { DietFormValues, IDiet } from '../types/IDiet'
import { DietMealFormValues } from '../types/IDietMeal'
import { AxiosApiService } from './api_service'

export const getDiets = async (
  options?: GenericPaginationOptions,
): Promise<GenericGetListResponse<IDiet>> => {
  const queryParams = qs.stringify(options)

  const response = await axios.get(`${API_ENDPOINT}/diets?${queryParams}`)

  return response.data
}

export const countDiets = async (): Promise<number> => {
  const response = await axios.get(`${API_ENDPOINT}/diets/count`)

  return response.data
}

export const getDiet = async (id: string) => {
  const response = await axios.get<IDiet>(`${API_ENDPOINT}/diets/${id}`)

  return response.data
}

export const addDiet = async (body: DietFormValues) => {
  const response = await axios.post(`${API_ENDPOINT}/diets`, body)

  return response.data
}

export const updateDiet = async (body: DietFormValues) => {
  const response = await axios.patch(`${API_ENDPOINT}/diets/${body.id}`, body)

  return response.data
}

export const deleteDiet = async (id: string) => {
  const response = await axios.delete(`${API_ENDPOINT}/diets/${id}`)

  return response.data
}

export const addDietDay = async (id: string) => {
  const response = await axios.post(`${API_ENDPOINT}/diets/${id}/days`)

  return response.data
}

export const deleteDietDay = async (id: string) => {
  const response = await axios.delete(`${API_ENDPOINT}/diets/${id}/days`)

  return response.data
}

export const toggleDietDay = async ({
  dietId,
  day,
  active,
}: {
  dietId: string
  day: number
  active: boolean
}) => {
  const response = await axios.patch(`${API_ENDPOINT}/diets/${dietId}/toggle_day`, { day, active })

  return response.data
}

export const addDietMeal = async (body: DietMealFormValues) => {
  const response = await axios.post(`${API_ENDPOINT}/diets/${body.diet_id}/meals`, body)

  return response.data
}

export const updateDietMeal = async (body: DietMealFormValues) => {
  const response = await axios.patch(`${API_ENDPOINT}/diets/${body.diet_id}/meals/${body.id}`, body)

  return response.data
}

export const deleteDietMeal = async ({
  dietId,
  dietMealId,
}: {
  dietId: string
  dietMealId: string
}) => {
  const response = await axios.delete(`${API_ENDPOINT}/diets/${dietId}/meals/${dietMealId}`)

  return response.data
}

export const getDietMeal = async ({
  dietId,
  dietMealId,
}: {
  dietId: string
  dietMealId: string
}) => {
  const response = await axios.get(`${API_ENDPOINT}/diets/${dietId}/meals/${dietMealId}`)

  return response.data
}

export const exportShoppingList = async (items: { diet_ids: string[] }) => {
  const queryParams = qs.stringify(items)

  return AxiosApiService.getAndToastError(`${API_ENDPOINT}/diets/shopping_list?${queryParams}`)
}

export const copyDiet = async (body: { id: string; newTitle: string }) => {
  const response = await axios.post(`${API_ENDPOINT}/diets/${body.id}/copy`, body)

  return response.data
}
