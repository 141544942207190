import { useState } from 'react'
import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { ProductFormValues } from '../../../../../types/IProduct'
import { ButtonContainer } from '../../../../Shared/Buttons/ButtonContainer'
import { searchFood } from '../../../../../api/usda'
import { Spinner } from '../../../../Shared/Loaders'
import { getCategories } from '../../../../../api/categories'
import { CATEGORIES } from '../../../../../constants/query_keys'
import { ICategory } from '../../../../../types/ICategory'

type ProductEditorFormProps = {
  onSubmit: (v: ProductFormValues) => void
}

type SearchResult = { hits: number; product: ProductFormValues | null }

const ProductFinderForm = ({ onSubmit }: ProductEditorFormProps) => {
  const [searching, setSearching] = useState(false)
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null)
  const { isLoading, data: categories } = useQuery([CATEGORIES], getCategories)

  function onEnter(callback: any) {
    return (e: any) => {
      if (e.key === 'Enter') {
        callback(e)
      }
    }
  }

  async function performSearch(e: any) {
    setSearching(true)
    const results = await searchFood(e.target.value)
    setSearchResult(results)
    setSearching(false)
  }

  function changeResultProductName(e: any) {
    if (searchResult?.product) {
      setSearchResult({
        ...searchResult,
        product: { ...searchResult.product, name: e.target.value },
      })
    }
  }

  function changeProductCategory(category: ICategory | null) {
    if (searchResult?.product) {
      setSearchResult({
        ...searchResult,
        product: { ...searchResult.product, category_id: category?.id },
      })
    }
  }

  function submit() {
    if (searchResult?.product) {
      return onSubmit(searchResult.product)
    }
  }

  const productNotFound = !searching && searchResult && !searchResult.hits
  const productFound = !searching && searchResult?.hits && searchResult.product

  return (
    <>
      <Box mb={4}>
        <TextField
          autoFocus
          label='Szukana nazwa'
          fullWidth
          variant='standard'
          onKeyDown={onEnter(performSearch)}
        />
      </Box>

      {searching && <Spinner />}

      {productNotFound && (
        <>
          <Typography variant='h6' paragraph>
            Wynik wyszukiwania
          </Typography>
          <Typography variant='body2'>Nie znaleziono.</Typography>
        </>
      )}

      {productFound && (
        <>
          <Typography variant='h6' paragraph>
            Wynik wyszukiwania
          </Typography>

          <Box mb={4}>
            <Box mb={2}>
              <TextField
                autoFocus
                label='Zapisz pod nazwą'
                fullWidth
                variant='standard'
                value={productFound.name}
                onChange={changeResultProductName}
                onKeyDown={onEnter(submit)}
              />
            </Box>
            <Box mb={4}>
              <Autocomplete
                fullWidth
                autoHighlight
                getOptionLabel={(option) => option.name || ''}
                options={categories?.rows || []}
                loading={isLoading}
                loadingText='wczytywanie...'
                onChange={(e, category) => changeProductCategory(category)}
                renderInput={(params) => (
                  <TextField {...params} label='Kategoria' variant='standard' />
                )}
              />
            </Box>

            <Grid container justifyContent='space-between' mb={1}>
              <Typography>Energia</Typography>
              <Typography>{`${productFound.energy} kcal`}</Typography>
            </Grid>

            <Grid container justifyContent='space-between' mb={1}>
              <Typography>Białka</Typography>
              <Typography>{`${productFound.protein} g`}</Typography>
            </Grid>

            <Grid container justifyContent='space-between' mb={1}>
              <Typography>Tłuszcze</Typography>
              <Typography>{`${productFound.fat} g`}</Typography>
            </Grid>

            <Grid container justifyContent='space-between' mb={1}>
              <Typography>Węglowodany</Typography>
              <Typography>{`${productFound.carb} g`}</Typography>
            </Grid>
          </Box>
        </>
      )}

      <ButtonContainer alignButtons='right'>
        <Button variant='contained' onClick={submit} disabled={!searchResult?.hits}>
          Dodaj
        </Button>
      </ButtonContainer>
    </>
  )
}

export { ProductFinderForm }
