import axios, { AxiosError } from "axios"
import { toast } from "react-toastify"

export class AxiosApiService {
  static async getAndToastError(url: string) {
    try {
      const response = await axios.get(url)

      return response.data
    } catch(error) {
      toastError(error)

      throw new Error((error as Error)?.message || 'Unknown Error.')
    }
  }

  static async postAndToastError<T>(url: string, body?: T) {
    try {
      const response = await axios.post(url, body)

      return response.data
    } catch(error) {
      toastError(error)

      throw new Error((error as Error)?.message || 'Unknown Error.')
    }
  }
}

function toastError(error: unknown) {
  if (error instanceof AxiosError) {
    const errorMessage = `${error.response?.status}: ${error.response?.data.message}`

    return toast.error(errorMessage)
  }

  return toast.error((error as Error)?.message || 'Unknown Error.')
}
