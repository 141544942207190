import { Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { Content } from '../../Layout'

type ShoppingListItem = {
  name: string
  qty: number
}

const DietShoppingList = () => {
  const { state: list } = useLocation()

  return (
    <Content>
      <>
        <Typography variant='h5'>Lista zakupów</Typography>
        <ul>
          {list.map((item: ShoppingListItem) => (
            <li key={item.name}>
              {item.name} {item.qty}g
            </li>
          ))}
        </ul>
      </>
    </Content>
  )
}

export { DietShoppingList }
