import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid'
import { useDispatch } from 'react-redux'

import { IMeal } from '../../../../../types/IMeal'
import { RowActions } from '../../../../Shared/Lists/RowActions'
import { openConfirm } from '../../../../../store/slices/ui'
import { PAGE_SIZE_DEFAULT } from '../../../../../constants'
import { MEAL_ORDER } from '../../../../../constants/meals'

type MealListProps = {
  meals: IMeal[]
  page: number
  count: number
  isLoading?: boolean
  onEdit: (row: IMeal) => void
  onRemove: (id: string) => void
  onPageChange: (newPage: number) => void
  onSortChange: (props: GridSortModel) => void
  onCopy: ({ id, title }: { id: string; title: string }) => void
  onSelect: (row: IMeal) => void
}

const MealList = ({
  meals,
  page,
  count,
  isLoading,
  onPageChange,
  onSortChange,
  onEdit,
  onRemove,
  onSelect,
  onCopy,
}: MealListProps) => {
  const dispatch = useDispatch()

  const columns: GridColDef<IMeal>[] = [
    { field: 'id', hide: true },
    { field: 'title', headerName: 'Nazwa', minWidth: 320, flex: 1, disableColumnMenu: true },
    {
      field: 'energy',
      headerName: 'Energia [kcal]',
      valueGetter: ({ row }) => row.macros?.energy,
      sortable: false,
      type: 'number',
      minWidth: 120,
      disableColumnMenu: true,
    },
    {
      field: 'protein',
      headerName: 'Białko [g]',
      valueGetter: ({ row }) => row.macros?.proteins,
      sortable: false,
      type: 'number',
      minWidth: 120,
      disableColumnMenu: true,
    },
    {
      field: 'fat',
      headerName: 'Tłuszcze [g]',
      valueGetter: ({ row }) => row.macros?.fats,
      sortable: false,
      type: 'number',
      minWidth: 120,
      disableColumnMenu: true,
    },
    {
      field: 'carb',
      headerName: 'Węglowodany [g]',
      valueGetter: ({ row }) => row.macros?.carbs,
      sortable: false,
      type: 'number',
      minWidth: 120,
      disableColumnMenu: true,
    },
    {
      field: 'suggested_order',
      headerName: 'Sugerowana pora posiłku',
      valueGetter: ({ row }) => (row.suggested_order ? MEAL_ORDER[row.suggested_order] : ''),
      sortable: false,
      minWidth: 190,
      align: 'right',
      headerAlign: 'right',
      disableColumnMenu: true,
    },
    {
      field: 'action',
      type: 'actions',
      sortable: false,
      width: 180,
      align: 'right',
      renderCell: ({ row }) => (
        <RowActions<IMeal>
          row={row}
          onEdit={onEdit}
          onRemove={confirmRemove}
          onCopy={(id) => onCopy({ id, title: row.title })}
        />
      ),
    },
  ]

  function confirmRemove(mealId: string) {
    dispatch(
      openConfirm({
        title: 'Usuń posiłek',
        text: 'Czy na pewno usunąć ten posiłek?',
        onConfirm: () => onRemove(mealId),
      }),
    )
  }

  return (
    <DataGrid
      columns={columns}
      rows={meals}
      rowCount={count}
      loading={isLoading}
      disableSelectionOnClick
      autoHeight
      pagination
      page={page}
      pageSize={PAGE_SIZE_DEFAULT}
      paginationMode='server'
      sortingMode='server'
      onRowClick={({ row }) => onSelect(row)}
      onPageChange={onPageChange}
      onSortModelChange={onSortChange}
      initialState={{
        sorting: {
          sortModel: [{ field: 'title', sort: 'asc' }],
        },
      }}
    />
  )
}

export { MealList }
