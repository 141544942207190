import { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { ButtonContainer } from '../../../../Shared/Buttons/ButtonContainer'
import { useQuery } from '@tanstack/react-query'
import { MEALS } from '../../../../../constants/query_keys'
import { IMeal } from '../../../../../types/IMeal'
import { DietMealFormValues } from '../../../../../types/IDietMeal'
import { getMeals } from '../../../../../api/meals'
import { getInitialMealProducts, ProductListForm } from '../../../../Shared/Forms/ProductListForm'
import { MEAL_ORDER, MEAL_ORDER_OPTIONS } from '../../../../../constants/meals'

type DietMealEditorProps = {
  dietMeal: DietMealFormValues
  days: number[]
  onSubmit: (v: DietMealFormValues) => void
}

const DietMealEditorForm = ({ dietMeal, days, onSubmit }: DietMealEditorProps) => {
  const [templateMeal, setTemplateMeal] = useState<IMeal | null>(null)
  const [mealOrder, setMealOrder] = useState<number | null>(null)

  const {
    isLoading,
    error,
    data: meals,
  } = useQuery([MEALS, 'search_products', mealOrder], () =>
    getMeals({ mealOrder, include: ['products'] }),
  )
  const { dietId } = useParams<keyof { dietId: string }>() as { dietId: string }

  const formMethods = useForm<DietMealFormValues>({
    defaultValues: {
      id: dietMeal.id,
      days: dietMeal.days,
      order: dietMeal.order,
      title: dietMeal.title || '',
      description: dietMeal.description || '',
      diet_meal_products: getInitialMealProducts(dietMeal.diet_meal_products),
    },
  })

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods

  useEffect(() => {
    if (templateMeal) {
      setValue('diet_meal_products', getInitialMealProducts(templateMeal.meal_products))
      setValue('title', templateMeal.title)
      setValue('description', templateMeal.description)
    }
    if (templateMeal?.suggested_order) {
      setValue('order', templateMeal.suggested_order)
    }
  }, [templateMeal, setValue])

  function prepareSubmitData(values: DietMealFormValues) {
    const diet_meal_products = values.diet_meal_products
      // remove all empty selections
      .filter(({ product_id }) => product_id)
      // product object is not needed because we already set product_id
      .map(({ qty, product_id }) => ({ qty, product_id, diet_meal_id: dietMeal.id }))

    onSubmit({ ...values, diet_id: dietId, diet_meal_products })
  }

  if (error) {
    return <>error</>
  }

  return (
    <FormProvider {...formMethods}>
      {dietMeal.id ? (
        <Box mb={4}>
          <Controller
            name='title'
            rules={{ required: { value: true, message: 'Wymagane' }, maxLength: 128 }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                label='Nazwa'
                fullWidth
                variant='standard'
                required
                error={Boolean(errors.title)}
                helperText={errors.title?.message}
              />
            )}
          />
        </Box>
      ) : (
        <Grid container spacing={1} mb={8}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              getOptionLabel={(option) => MEAL_ORDER[option] || ''}
              options={MEAL_ORDER_OPTIONS}
              onChange={(e, option) => setMealOrder(option)}
              renderInput={(params) => (
                <TextField {...params} label='Pora wyszukiwanego posiłku' variant='standard' />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              autoHighlight
              getOptionLabel={(option) => option.title || ''}
              options={meals?.rows || []}
              loading={isLoading}
              loadingText='wczytywanie...'
              onChange={(e, meal) => setTemplateMeal(meal)}
              renderInput={(params) => (
                <TextField {...params} label='Posiłek' variant='standard' required />
              )}
            />
          </Grid>
        </Grid>
      )}

      <Grid container mb={4} alignItems='flex-start' spacing={2}>
        <Grid item xs={6}>
          <Controller
            name='order'
            rules={{ required: { value: true, message: 'Wymagane' } }}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth variant='standard' error={Boolean(errors.order)}>
                <InputLabel>Pora posiłku</InputLabel>
                <Select {...field}>
                  {Object.entries(MEAL_ORDER).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.order && (
                  <FormHelperText variant='standard'>{errors.order.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name='days'
            rules={{ required: { value: true, message: 'Wybierz co najmniej jeden dzień' } }}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth variant='standard' error={Boolean(errors.days)}>
                <InputLabel>Dzień</InputLabel>
                <Select {...field} multiple>
                  {days.map((day) => (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </Select>
                {errors.days && (
                  <FormHelperText variant='standard'>{errors.days.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <ProductListForm associationName='diet_meal_products' />

      <ButtonContainer alignButtons='right'>
        <Button
          variant='contained'
          disabled={(!dietMeal.id && !templateMeal) || isSubmitting}
          onClick={handleSubmit(prepareSubmitData)}
        >
          Wyślij
        </Button>
      </ButtonContainer>
    </FormProvider>
  )
}

export { DietMealEditorForm }
