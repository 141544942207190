import { Box, Grid, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import SetMealIcon from '@mui/icons-material/SetMeal'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import MenuBookIcon from '@mui/icons-material/MenuBook'

import { Content } from '../../Layout'
import { DIETS_COUNT, MEALS_COUNT, PRODUCTS_COUNT } from '../../../constants/query_keys'
import { countProducts } from '../../../api/products'
import { countMeals } from '../../../api/meals'
import { countDiets } from '../../../api/diets'

const Dashboard = () => {
  const productsCount = useQuery([PRODUCTS_COUNT], countProducts)
  const mealsCount = useQuery([MEALS_COUNT], countMeals)
  const dietsCount = useQuery([DIETS_COUNT], countDiets)

  const isLoading = productsCount.isLoading || mealsCount.isLoading || dietsCount.isLoading
  const error = productsCount.error || mealsCount.error || dietsCount.error

  return (
    <Content isLoading={isLoading} error={error as Error}>
      <Box mt={4}>
        <Grid container justifyContent='space-around'>
          <Grid
            item
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            md={4}
            xs={12}
            mb={4}
          >
            <SetMealIcon fontSize='large' />
            <Typography variant='h5' my={2}>
              Dodane produkty
            </Typography>
            <Typography variant='h3'>{productsCount.data}</Typography>
          </Grid>

          <Grid
            item
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            md={4}
            xs={12}
            mb={4}
          >
            <RestaurantIcon fontSize='large' />
            <Typography variant='h5' my={2}>
              Dodane posiłki
            </Typography>
            <Typography variant='h3'>{mealsCount.data}</Typography>
          </Grid>

          <Grid
            item
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            md={4}
            xs={12}
            mb={4}
          >
            <MenuBookIcon fontSize='large' />
            <Typography variant='h5' my={2}>
              Dodane diety
            </Typography>
            <Typography variant='h3'>{dietsCount.data}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Content>
  )
}

export { Dashboard }
