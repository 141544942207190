import { useQuery } from '@tanstack/react-query'

import { getMeal } from '../../../../../api/meals'
import { MEALS } from '../../../../../constants/query_keys'
import { IMeal } from '../../../../../types/IMeal'
import { GenericDrawer } from '../../../../Shared/Lists/GenericDrawer'
import { Spinner } from '../../../../Shared/Loaders'
import { MealOverview } from '../../../../Shared/MealOverview'

type MealViewerDrawerProps = {
  open: boolean
  mealId?: string | null
  onEdit: (meal: IMeal) => void
  onClose: () => void
}

const MealViewerDrawer = ({ open, mealId, onEdit, onClose }: MealViewerDrawerProps) => {
  const { isLoading, data: meal } = useQuery([MEALS, mealId || ''], () => getMeal(mealId || ''), {
    enabled: Boolean(mealId),
  })

  return (
    <GenericDrawer
      open={open}
      onEdit={() => onEdit(meal)}
      onClose={onClose}
      title={meal?.title || ''}
    >
      {isLoading && <Spinner />}
      {!isLoading && open && meal && <MealOverview meal={meal} />}
    </GenericDrawer>
  )
}

export { MealViewerDrawer }
