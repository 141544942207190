import { Box, Card, CardActionArea, CardActions, CardContent, Typography } from '@mui/material'
import ContrastIcon from '@mui/icons-material/Contrast'
import { useDispatch } from 'react-redux'
import { getMealMacros } from '../../../../../helpers/meals'
import { openConfirm } from '../../../../../store/slices/ui'
import { IDietMeal } from '../../../../../types/IDietMeal'
import { RowActions } from '../../../../Shared/Lists/RowActions'
import { MEAL_ORDER } from '../../../../../constants/meals'

interface DietMealCardProps {
  meal: IDietMeal
  onEdit: (m: IDietMeal) => void
  onRemove: (id: string) => void
  onSelect: (m: IDietMeal) => void
}

const DietMealCard = ({ meal, onEdit, onRemove, onSelect }: DietMealCardProps) => {
  const dispatch = useDispatch()
  const { energy, proteins, fats, carbs } = getMealMacros(meal.diet_meal_products)

  const { title, days } = meal
  const multipleDays = days.length > 1

  function confirmRemove(dietMealId: string) {
    dispatch(
      openConfirm({
        title: 'Usuń posiłek z diety',
        text: 'Czy na pewno usunąć ten posiłek?',
        onConfirm: () => onRemove(dietMealId),
      }),
    )
  }

  return (
    <Card
      variant='outlined'
      sx={{ display: 'flex', borderTop: 0, borderBottom: 0, borderRight: 0 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CardActionArea onClick={() => onSelect(meal)}>
          <CardContent>
            <Typography variant='overline'>{MEAL_ORDER[meal.order]}</Typography>
            <Typography variant='h6' display='flex' alignItems='center'>
              {title} {multipleDays ? <ContrastIcon style={{ marginLeft: 8 }} /> : null}
            </Typography>
            <Typography variant='subtitle1' color='text.secondary' component='div'>
              K:{energy} / B:{proteins} / W:{carbs} / T:{fats}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Box>
      <CardActions>
        <RowActions<IDietMeal> row={meal} onEdit={onEdit} onRemove={confirmRemove} />
      </CardActions>
    </Card>
  )
}

export { DietMealCard }
